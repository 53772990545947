<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
          <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
        </div>
        <form action="">
          <div class="form-row">
            <div class="col-md-6">
              <span class="custom-required">
                <span>* Import file must be xlsx, xls, csv format.</span> <br>
                <span>* Category must be store in DB before items import or insert.</span> <br>
              </span>
              <label for="product_file">Items Import File <span class="custom-required">*</span></label>
              <input type="file" v-validate="'required'" id="product_file" name="product_file" class="form-control form-control-sm" ref="inputFile" @change="getFile()" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
              <div class="invalid-feedback">Items import file is required</div>
            </div>
          </div>
          <div class="form-row mt-2 mb-1">
            <a-button class="btn btn-success" :loading="btnLoading" @click.prevent="upload()"><i class="fa fa-upload mr-1" aria-hidden="true"></i>Upload</a-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
export default {
  name: 'insert',
  components: { },
  data() {
    return {
      btnLoading: false,
      validation_errors: {},
      show: false,
      product_file: '',
    }
  },
  mounted() { },
  methods: {
    getFile() {
      this.product_file = this.$refs.inputFile.files[0]
      if (this.product_file.size > 10000000) {
        this.$bvToast.toast('File size not greater then 10 MB', {
          title: 'Error',
          autoHideDelay: 5000,
          variant: 'error',
        })
        this.product_file = ''
      }
    },
    upload() {
      this.$validator.validate().then(valid => {
        if (valid) {
          const formData = new FormData()
          formData.append('product_file', this.product_file)
          this.btnLoading = true
          apiClient.post('api/product-import/', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Content-type': 'application/json',
            },
          }).then(response => {
            this.btnLoading = false
            this.$bvToast.toast(response.data.message, {
              title: 'Success',
              autoHideDelay: 5000,
              variant: 'success',
            })
            this.$refs.inputFile.value = ''
          }).catch(error => {
            this.btnLoading = false
            this.show = true
            this.validation_errors = error.response.data.errors
            this.hide()
          })
        }
      })
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>

<style scoped>
.form-control-sm {
  height: calc(1.7em + .5rem + 2px) !important;
}
</style>
